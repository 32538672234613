@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  margin-left: 50px;
  max-height: 100vh;

  @include vp--tablet {
    margin-left: 20px;
  }

  @include vp--740 {
    margin-left: 10px;
  }

  &:nth-of-type(1) {
    margin-left: 5vw;
    .date__line::before {
      display: none;
    }
  }

  &:last-of-type {
    margin-right: 5vw;
  }
}

.generalWrapper {
  width: size-rem(385px);

  @include vp--740 {
    width: 100%;
  }
}

.generalCard {
  width: size-rem(385px);
  height: size-rem(500px);
  display: block;
  position: relative;
  overflow: hidden;

  @include vp--740 {
    width: 100%;
    height: size-rem(450px, true);
  }
}

.generalStage {
  font-family: $main-font-family;
  font-size: size-rem(32px);
  font-weight: 350;
  line-height: size-rem(35.2px);
  padding-bottom: size-rem(40px);
  text-align: center;

  @include vp--740 {
    font-size: size-rem(22px, true);
    font-weight: 350;
    line-height: size-rem(24.2px, true);
    letter-spacing: -0.02em;
    text-align: left;
    padding-top: size-rem(16px, true);
    padding-bottom: 0;
  }
}

.card {
  display: block;
  width: size-rem(447px);
  height: size-rem(580px);
  max-width: 38.5vh;
  max-height: 50vh;
  position: relative;
  overflow: hidden;

  @include vp--tablet {
    width: size-rem(447px, false, false, true);
    height: size-rem(580px, false, false, true);
  }

  @include vp--740 {
    width: size-rem(294px, true);
    height: size-rem(400px, true);
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(28, 28, 28, 0.3);
    opacity: 0;
    transition: 0.15s;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-size: size-rem(32px);
      color: $clr-white;
      margin-top: size-rem(30px);
    }

    &:hover {
      opacity: 1;

      @include vp--740 {
        opacity: 0;
      }
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__img_common {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
  }
}

.contentLength {
  font-family: $main-font-family;
  font-size: size-rem(32px);
  font-weight: 350;
  line-height: size-rem(35.2px);
  text-align: left;
}

.paragraph {
  font-size: size-rem(18px);
  line-height: 140%;
  margin-top: size-rem(10px);
  min-height: size-rem(25px);
  font-family: $main-font-family;

  @include vp--tablet3 {
    margin-top: size-rem(10px, false, false, true);
    font-size: size-rem(18px, false, false, true);
    min-height: size-rem(25px, false, false, true);
  }

  @include vp--740 {
    font-size: size-rem(18px, true);
    margin-top: size-rem(10px, true);
    min-height: size-rem(25px, true);
  }
}

.date {
  margin-top: size-rem(45px);
  height: size-rem(80px);
  padding: 0;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include vp--tablet {
    margin-top: size-rem(40px, false, false, true);
    height: size-rem(80px, false, false, true);
  }

  @include vp--740 {
    margin-top: size-rem(40px, true);
    height: size-rem(80px, true);
  }

  &__inner {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    z-index: 2;
    padding: 0 size-rem(44px);

    @include vp--tablet3 {
      padding: 0 size-rem(36px, false, false, true);
    }

    @include vp--740 {
      padding: 0 size-rem(36px, true);
    }
  }

  &__day {
    font-weight: 350;
    font-size: size-rem(40px) !important;
    line-height: 110%;
    font-family: $main-font-family;
    letter-spacing: -0.02em;

    @include vp--tablet3 {
      font-size: size-rem(40px, false, false, true) !important;
    }

    @include vp--740 {
      font-size: size-rem(40px, true) !important;
    }
  }

  &__month {
    font-size: size-rem(18px) !important;
    font-family: $main-font-family;
    @include vp--tablet3 {
      font-size: size-rem(18px, false, false, true) !important;
    }

    @include vp--740 {
      font-size: size-rem(18px, true) !important;
    }
  }

  &__year {
    font-size: size-rem(18px) !important;
    font-family: $main-font-family;
    @include vp--tablet3 {
      font-size: size-rem(18px, false, false, true) !important;
    }

    @include vp--740 {
      font-size: size-rem(18px, true) !important;
    }
  }

  span {
    font-weight: 350;
    font-size: size-rem(18px) !important;
    line-height: 140%;
    color: $clr-dark-grey;

    @include vp--tablet3 {
      font-size: size-rem(18px, false, false, true) !important;
    }

    @include vp--740 {
      font-size: size-rem(18px, true) !important;
    }
  }

  &__line {
    position: absolute;
    background-color: $clr-grey;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    padding: 0;
    margin: 0;
    z-index: 1;
  }
}

.svgBox {
  width: size-rem(160px);
  height: size-rem(160px);
  position: relative;
}
.svg {
  width: 100%;
  height: 100%;
}

.generalDate {
  @include vp--740 {
    margin-top: size-rem(30px, true);
  }
}
