@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.wrapper {
  padding: size-rem(140px) 0 size-rem(100px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include vp--740 {
    padding: size-rem(60px, true) 5%;
  }
}

.title {
  font-family: $main-font-family;
  font-size: size-rem(85px);
  font-weight: 350;
  line-height: size-rem(76.5px);
  letter-spacing: size-rem(-2px);
  text-align: center;

  @include vp--740 {
    font-size: size-rem(40px, true);
    font-weight: 350;
    line-height: size-rem(36px, true);
    letter-spacing: -0.02em;
    text-align: left;
  }
}

.progressBox {
  margin-top: size-rem(80px);
  margin-bottom: size-rem(60px);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: size-rem(104px);

  @include vp--740 {
    flex-direction: column;
    gap: size-rem(40px, true);
    margin-top: size-rem(40px, true);
    margin-bottom: size-rem(40px, true);
  }
}

.verticalLine {
  width: 1px;
  height: size-rem(699px);
  background-color: $clr-grey;
}

.button {
  padding: size-rem(26px) size-rem(30px);
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-weight: 350;
  line-height: size-rem(19.2px);
  text-align: left;
  width: fit-content;
  border: 1px solid $clr-grey;
  border-radius: size-rem(50px);
  background-color: transparent;
  cursor: pointer;

  @include vp--740 {
    border-radius: size-rem(50px, true);
    padding: size-rem(19px, true) size-rem(20px, true) size-rem(16px, true);
    font-size: size-rem(16px, true);
    font-weight: 350;
    line-height: size-rem(19.2px, true);
    text-align: left;
    height: fit-content;
  }
}
